<template>
  <div class="search_content">
    <div class="search-bg"></div>
    <div class="search-bg-2">
      <icon name="bg-dot-circle" class="pc-bg2"></icon>
      <icon name="bg-dot-circle" class="sp-bg2"></icon>
    </div>
    <div class="search-results-area">
      <div class="search-results">
        <commonTitle title="SEARCH RESULTS" subTitle="検索結果"></commonTitle>
        <template v-if="viewState.lectures.length > 0">
          <div class="flex-video">
            <template
              v-for="(lecture, index) in viewState.lectures"
              :key="index"
            >
              <div class="flex-video-item">
                <commonVideoItem
                  :imgIndex="index % 4"
                  :video="lecture"
                  :levels="viewState.levels"
                  :isEnterprise="isEnterprise"
                  style="margin-top: 40px"
                ></commonVideoItem>
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="empty-message-text">
            検索条件に一致する講座が見つかりませんでした。
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import util from "@/utils/util";
import http from "@/utils/http";
import commonTitle from "@/components/CommonTitle/CommonTitle.vue";
import commonVideoItem from "@/components/CommonVideo/CommonVideoItem.vue";
import { defineComponent, reactive, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import { useGlobalStore } from "@/store/global";
export default defineComponent({
  components: {
    commonVideoItem,
    commonTitle,
  },
  setup() {
    const store = useStore();
    const { searchStore } = useGlobalStore();

    const viewState = reactive({
      response: {
        status: 0,
        data: {},
      },
      isDisplayed: false,
      lectures: {},
      levels: [],
    });

    const isSp = computed(() => store.state.isSp);
    const isEnterprise = computed(() => store.state.user.isEnterprise);
    const isLogin = computed(() => store.state.user.isLogin);

    window.scrollTo(0, 0);

    const fetchLevels = () => {
      return http
        .get("/api/levels")
        .then((response) => {
          viewState.levels = util.keyBy(response.data, "id");
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    };
    const fetchLectures = () => {
      if (!searchStore.text) {
        return;
      }

      const encodedSearchText = encodeURI(searchStore.text);

      if (isEnterprise.value) {
        return http
          .get(`/api/enterprise-lectures/?search=${encodedSearchText}&order=3`)
          .then((response) => {
            viewState.lectures = response.data;
            return response;
          })
          .catch((error) => {
            return error.response;
          });
      } else {
        return http
          .get(`/api/lectures/?search=${encodedSearchText}&order=1`)
          .then((response) => {
            viewState.lectures = response.data;
            return response;
          })
          .catch((error) => {
            return error.response;
          });
      }
    };
    const init = async () => {
      if (!isEnterprise.value) {
        fetchLevels();
      }
      await fetchLectures();

      viewState.isDisplayed = true; // 表示OK
    };
    const reInit = async () => {
      console.log("re-init");
      await init();
    };

    watch(
      () => searchStore.text,
      () => {
        fetchLectures();
      }
    );
    watch(
      () => store.state.user.isEnterprise,
      () => {
        reInit();
      }
    );

    onMounted(async () => {
      if (isLogin.value && store.state.user.servicePlanId === 0) {
        // ログイン済み、かつサーピスプラン未取得の場合、
        // 顧客情報を取得したタイミングで講座情報を取得する
        console.log("skip on-mount process");
        return;
      }

      await init();
    });

    return {
      util,
      viewState,
      isSp,
      isEnterprise,
    };
  },
});
</script>

<style lang="scss" scoped>
.search_content {
  max-width: 1366px;
  position: relative;
  margin: 0 auto 115px;
  @include mq(sm) {
    margin-bottom: 80px;
  }

  .search-bg {
    max-width: 1368px;
    width: 1368px;
    min-height: 50px;
    background-image: url("../../assets/img/top-login-top-bg.png");
    background-size: 100%;
    z-index: 1;
    @include mq(sm) {
      width: 375px;
      background-image: url("../../assets/img/sp-top-bg.png");
    }
  }
  .search-bg-2 {
    position: absolute;
    width: 620px;
    height: 620px;
    left: -310px;
    top: -280px;
    z-index: -1;
    @include mq(sm) {
      left: -141px;
      top: -91px;
    }
    .pc-bg2 {
      min-width: 620px;
      min-height: 620px;
      @include mq(sm) {
        display: none;
      }
    }
    .sp-bg2 {
      display: none;
      @include mq(sm) {
        display: block;
        width: 282px;
        height: 282px;
      }
    }
  }
  .search-results-area {
    padding-left: 50px;
    padding-top: 65px;
    max-width: 1366px;
    width: 100%;
    @include mq(sm) {
      padding-left: 20px;
      padding-top: 60px;
      padding-right: 50px;
      width: 375px;
    }
  }
  .search-results {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 1349px;
    @include mq(sm) {
      width: 335px;
    }

    .empty-message-text {
      padding-left: 50px;
      padding-top: 65px;
      line-height: 1.7;
    }

    .common-title {
      &::before {
        top: 0px;
        @include mq(sm) {
          top: 30px;
        }
      }
      ::v-deep(.common-title-area) {
        @include mq(sm) {
          height: unset;
        }
        .common-titles-title {
          font-family: "Roboto Condensed", sans-serif;
          font-weight: 700;
          font-size: 40px;
          line-height: 60px;
          display: flex;
          align-items: center;
          letter-spacing: 0.05em;
          color: $black;
          @include mq(sm) {
            margin-top: 10px;
            font-family: "Roboto Condensed", sans-serif;
            font-weight: 700;
            font-size: 30px;
            line-height: 40px;
            letter-spacing: 0.08em;
            color: $black;
            text-align: left;
            margin-right: 50px;
          }
        }
        .common-titles-sub-title {
          width: 500px;
          display: flex;
          justify-content: flex-start;
          line-height: unset;
          @include mq(sm) {
            line-height: 100%;
          }
        }
      }
    }
  }

  .untitled_half {
    position: absolute;
    top: 1001px;
    left: -1px;
    transform: rotate(1.36deg);
    z-index: -1;
  }

  .imgBg {
    position: absolute;
    width: 282px;
    height: 282px;
    top: 446px;
    left: -140px;
    z-index: -1;
  }

  .flex-video {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: 65px;
    margin-right: 65px;
    width: 100%;

    @include mq(sm) {
      margin-top: 15px;
      margin-left: 30px;
      margin-right: 30px;
      width: 275px;
      height: auto;
      .flex-video-items {
        height: 418px;
      }
    }

    .flex-video-item {
      margin-right: 28px;
    }
  }

  .swiper_item {
    padding-top: 65px;
    padding-left: 50px;

    .swiper_line {
      width: 1136px;
      height: 0.5px;
      background-color: $black-50;
      margin-left: 65px;
      margin-top: 65px;
    }

    ::v-deep(.common-titles-title) {
      white-space: normal !important;
      margin-right: 20px !important;
      text-align: left !important;
    }

    ::v-deep(.common-titles-sub-title) {
      width: 77px !important;
      font-weight: bold !important;
      font-size: 20px !important;
      display: flex !important;
      align-items: center !important;
      text-align: center !important;
      letter-spacing: 0.08em !important;
      color: $secondary !important;
      flex: none !important;
      order: 1 !important;
      flex-grow: 0 !important;
      opacity: 1 !important;
    }

    ::v-deep(.common-title-area) {
      height: 70px;
      margin-right: 205px;
    }

    ::v-deep(.common-title::before) {
      top: 4px !important;
    }
  }

  .swiper_sp_item {
    width: 100%;
    padding-top: 60px;
    padding-left: 20px;

    .swiper_line {
      width: 275px;
      height: 0.5px;
      background-color: $black-50;
      margin-left: 30px;
    }

    ::v-deep(.common-title::before) {
      top: 15px !important;
    }

    ::v-deep(.common-titles-sub-title) {
      display: none !important;
    }
  }
}
</style>
